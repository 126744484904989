import { gql } from '@apollo/client';

const ERRORS_FRAGMENT = gql`
  fragment ErrorsFragment on Errors {
    authenticationError {
      message
      helpMessage
    }
    authorizationError {
      message
      helpMessage
    }
    rateLimitError {
      message
      helpMessage
    }
    notFoundError {
      message
      helpMessage
    }
    subscriptionError {
      message
      helpMessage
    }
    genericErrors {
      message
      helpMessage
    }
    fieldErrors {
      field
      message
      helpMessage
    }
  }
`;

const REPORT_QUESTION_FRAGMENT = gql`
  fragment ReportQuestionFragment on ReportQuestionObject {
    questionId
    question
    options
    questionType
    questionField
    sharedName
    nextQuestion
    previousQuestion
  }
`;

const REPORT_GROUP_FRAGMENT = gql`
  fragment ReportGroupFragment on ReportGroupObject {
    identifier
    createdAt
    baselineStartDate
    baselineEndDate
    targetEndDate
    reports {
      ...ReportFragment
    }
  }
`;

const PUBLIC_ENTITY_FRAGMENT = gql`
  fragment PublicEntityFragment on PublicEntityObject {
    identifier
    name
    sector
    sectorPercentile
    score
    publicBranchIdentifier
    badge
  }
`;

const ENTITY_FRAGMENT = gql`
  fragment EntityFragment on EntityObject {
    id
    identifier
    name
    sector
    inPublicDirectory
    companyDescription
    revenue
    employeeCountRange
    estimateCompleted
    reportingRequirementsCompleted
    reportingPeriods {
      id
      identifier
      startDate
      endDate
    }
    geographicalBoundaries {
      us
      uk
      eu
      ca
    }
    recommendedFrameworks {
      tcfd
      csrd
      issb
      gri
    }
    billing {
      identifier
      subscriptionReconciliationRequired
      failedPayment {
        plan {
          identifier
          tier
          name
        }
      }
      activePlan {
        tier
        name
      }
    }
    tree {
      head {
        id
        identifier
      }
      heads {
        id
        identifier
        createdAt
        name
        path
        permissions {
          identifier
          read
          create
          edit
          delete
          branch {
            identifier
            name
          }
        }
        userPermissions {
          read
          create
          edit
          delete
        }
      }
    }
  }
`;

const REPORT_FRAGMENT = gql`
  fragment ReportFragment on ReportObject {
    id
    identifier
    createdAt
    modifiedAt
    reportType
    reportVersion
    humanReadableReportType
    numberOfPrimaryQuestions
    reportGroup {
      identifier
      createdAt
      baselineStartDate
      baselineEndDate
      targetEndDate
      reports {
        identifier
        humanReadableReportType
        numberOfPrimaryQuestions
        reportVersion
        primaryQuestionsAndAnswers {
          reportQuestion {
            ...ReportQuestionFragment
          }
          contextualAnswer {
            id
            identifier
            questionId
            reportAnswer {
              ...ReportAnswerFragment
            }
            children {
              id
              identifier
              questionId
              reportAnswer {
                ...ReportAnswerFragment
              }
            }
            isFamilyCompleted
            family {
              id
              identifier
              createdAt
              modifiedAt
              questionId
              reportQuestion {
                ...ReportQuestionFragment
              }
              reportAnswer {
                ...ReportAnswerFragment
              }
            }
          }
        }
      }
    }
    primaryQuestionsAndAnswers {
      reportQuestion {
        ...ReportQuestionFragment
      }
      contextualAnswer {
        id
        identifier
        createdAt
        modifiedAt
        questionId
        reportAnswer {
          ...ReportAnswerFragment
        }
        children {
          id
          identifier
          createdAt
          modifiedAt
          questionId
          reportAnswer {
            ...ReportAnswerFragment
          }
        }
        isFamilyCompleted
        family {
          id
          identifier
          createdAt
          modifiedAt
          questionId
          reportQuestion {
            ...ReportQuestionFragment
          }
          reportAnswer {
            ...ReportAnswerFragment
          }
        }
      }
    }
  }
`;

const REPORT_ANSWER_FRAGMENT = gql`
  fragment ReportAnswerFragment on ReportAnswerObject {
    id
    identifier
    createdAt
    submitted
    answerType
    answerField
    sharedName
    isShared
    answer {
      ... on StringAnswerObject {
        value
      }
      ... on BranchObject {
        name
      }
      ... on EntityObject {
        tree {
          head {
            name
          }
        }
      }
      ... on TreeObject {
        head {
          name
        }
      }
      ... on AccountObject {
        user {
          emailAddress
        }
      }
      ... on GoalObject {
        title
      }
    }
  }
`;

const CONTEXTUAL_REPORT_ANSWER_FRAGMENT = gql`
  fragment ContextualReportAnswerFragment on ContextualReportAnswerObject {
    id
    identifier
    createdAt
    modifiedAt
    questionId
    report {
      ...ReportFragment
    }
    reportAnswer {
      ...ReportAnswerFragment
    }
    reportQuestion {
      ...ReportQuestionFragment
    }
    children {
      id
      identifier
      createdAt
      modifiedAt
      questionId
      reportAnswer {
        ...ReportAnswerFragment
      }
    }
    isFamilyCompleted
    family {
      id
      identifier
      createdAt
      modifiedAt
      questionId
      reportQuestion {
        ...ReportQuestionFragment
      }
      reportAnswer {
        ...ReportAnswerFragment
      }
    }
  }
`;

const USER_FRAGMENT = gql`
  fragment UserFragment on UserObject {
    id
    identifier
    createdAt
    modifiedAt
    emailAddress
    emailVerified
    isSuperuser
    isStaff
    isPasswordSet
    entity {
      billing {
        ...BillingFragment
      }
    }
    activeAccount {
      isOwner
    }
    profile {
      firstName
      lastName
      phone
    }
    settings {
      mfaEnabled
    }
  }
`;

const BILLING_FRAGMENT = gql`
  fragment BillingFragment on BillingObject {
    identifier
    availableCredit
    currentMonthlyUsage
    nextInvoiceDate
    activePlan {
      name
      description
      price
      monthlyUsageLimit
      expensesIncluded
      tier
      frequency
      credit
    }
    paymentMethods {
      identifier
      lastFour
      expMonth
      expYear
      brand
    }
    failedPayment {
      plan {
        tier
        name
      }
    }
    activeSubscription {
      identifier
      isCancelled
      validUntil
      pendingActivation
      pendingCancellation
      paymentFailed
      promotionCode
    }
  }
`;

const PROJECT_FRAGMENT = gql`
  fragment ProjectFragment on ProjectObject {
    id
    identifier
    createdAt
    modifiedAt
    name
    organization
    creator {
      identifier
      emailAddress
      profile {
        identifier
        firstName
        lastName
      }
    }
    startDate
    endDate
    description
    location
    attributes {
      identifier
      name
    }
    minPrice
    maxPrice
    amount
    amountListed
    images {
      identifier
      default
      url
    }
  }
`;

const BLOGPOST_FRAGMENT = gql`
  fragment BlogpostFragment on BlogpostObject {
    id
    identifier
    createdAt
    modifiedAt
    title
    subtitle
    author
    date
    timeToRead
    published
    thumbnailImage {
      name
      size
      url
    }
    sections {
      id
      identifier
      title
      content
      orderingPosition
      images {
        id
        identifier
        subtitle
        image {
          name
          size
          url
        }
      }
    }
    tags {
      id
      identifier
      title
    }
  }
`;

const GOAL_FRAGMENT = gql`
  fragment GoalFragment on GoalObject {
    id
    identifier
    title
    description
    createdAt
    frequency
    baselineStartDate
    baselineEndDate
    targetEndDate
    baselineEmissionRate
    targetEmissionRate
    percentReduction
    emissionsAtTime {
      projectedKgCo2eAtTime
      actualKgCo2eAtTime
      targetKgCo2eAtTime
      datetime
    }
    emissionRatesPerTime {
      projectedKgCo2ePerTime
      actualKgCo2ePerTime
      targetKgCo2ePerTime
      datetime
    }
    actions {
      id
      identifier
      title
      description
      startDate
      endDate
      category
      state
      isComplete
      goal {
        id
        identifier
        title
        baselineStartDate
        targetEndDate
        baselineEndDate
        branch {
          userPermissions {
            create
          }
        }
      }
    }
    branch {
      id
      identifier
      userPermissions {
        read
        create
        edit
        delete
      }
    }
  }
`;

const GOAL_ACTION_FRAGMENT = gql`
  fragment GoalActionFragment on GoalActionObject {
    id
    identifier
    title
    description
    startDate
    state
    endDate
    category
    isComplete
    goal {
      id
      title
      description
      identifier
      baselineStartDate
      baselineEndDate
      targetEndDate
      branch {
        userPermissions {
          read
          create
          edit
          delete
        }
      }
    }
  }
`;

const SURVEY_SUBMISSION_ACCOUNT_FRAGMENT = gql`
  fragment SurveySubmissionAccountFragment on AccountObject {
    identifier
    user {
      identifier
      emailVerified
      entity {
        name
        billing {
          activePlan {
            tier
          }
        }
      }
    }
    entity {
      sustainabilityStatement
      tree {
        head {
          identifier
          name
          missionStatement
          goals {
            title
            description
            identifier
            baselineEndDate
            percentReduction
            baselineStartDate
            targetEndDate
            actions {
              title
              description
              startDate
              endDate
              category
            }
          }
        }
      }
    }
  }
`;

const BRANCH_SELECTOR_BRANCH_FRAGMENT = gql`
  fragment BranchSelectorBranchFragment on BranchObject {
    id
    identifier
    name
    id
    path
    goal {
      identifier
    }
    goals {
      identifier
    }
  }
`;

const REPORTING_PERIOD_SELECTOR_PERIOD_FRAGMENT = gql`
  fragment ReportingPeriodSelectorPeriodFragment on ReportingPeriodObject {
    id
    identifier
    startDate
    endDate
  }
`;

const PUBLIC_SURVEY_FRAGMENT = gql`
  fragment PublicSurveyFragment on PublicSurveyObject {
    primaryQuestionIds
    identifier
    name
    description
    questions {
      identifier
      questionId
      required
      dependsOnParentAnswer
      errorMessage
      text
      frontendType
      backendType
      options
      isMulti
      parentQuestion {
        identifier
        questionId
        text
      }
    }
  }
`;

const SURVEY_SUBMISSION_FRAGMENT = gql`
  fragment SurveySubmissionFragment on SurveySubmissionObject {
    identifier
    currentQuestionId
    isSubmitted
    answers {
      identifier
      answerData
      file {
        name
        size
        url
      }
      surveyQuestion {
        text
        identifier
        questionId
        backendType
      }
    }
    branch {
      id
      name
      identifier
    }
  }
`;

const BRANCH_FRAGMENT = gql`
  fragment BranchFragment on BranchObject {
    id
    identifier
    index
    createdAt
    modifiedAt
    name
    path
    location
    description
    isProcessing
    isFailed
    isBranchOrChildrenProcessing
    missionStatement
    visionStatement
    toReviewKgCo2e: kgCo2e(states: TO_REVIEW)
    isNeedingAttention(
      startDate: $startDate
      endDate: $endDate
      states: $states
    )
    parent {
      id
      identifier
      name
      isBranchOrChildrenProcessing
      isNeedingAttention(
        startDate: $startDate
        endDate: $endDate
        states: $states
      )
    }
    documents {
      id
      identifier
      isPublic
      isImage
      file {
        name
        size
        url
      }
    }
    images {
      identifier
      isPublic
      isImage
      file {
        name
        size
        url
      }
    }
    fileType
    file {
      name
      size
      url
    }
    branchType
    isPinned
    totalSpend(
      startDate: $startDate
      endDate: $endDate
      states: $states
    )
    kgCo2eOffset
    kgCo2e(startDate: $startDate, endDate: $endDate, states: $states)
    kgCo2ePerSpend(
      startDate: $startDate
      endDate: $endDate
      states: $states
    )
    scope1(startDate: $startDate, endDate: $endDate, states: $states)
    scope2(startDate: $startDate, endDate: $endDate, states: $states)
    highestEmittingCategories(
      states: $states
      startDate: $startDate
      endDate: $endDate
    ) {
      ghgCategory
      kgCo2e
    }
    scope3(startDate: $startDate, endDate: $endDate, states: $states)
    highestEmittingScope1Categories: highestEmittingCategories(
      states: $states
      startDate: $startDate
      endDate: $endDate
      scope: [SCOPE_1]
    ) {
      ghgCategory
      kgCo2e
    }
    highestEmittingScope2Categories: highestEmittingCategories(
      states: $states
      startDate: $startDate
      endDate: $endDate
      scope: [SCOPE_2]
    ) {
      ghgCategory
      kgCo2e
    }
    highestEmittingScope3Categories: highestEmittingCategories(
      states: $states
      startDate: $startDate
      endDate: $endDate
      scope: [SCOPE_3]
    ) {
      ghgCategory
      kgCo2e
    }
    children {
      id
      identifier
      index
      isBranchOrChildrenProcessing
      isNeedingAttention(
        startDate: $startDate
        endDate: $endDate
        states: $states
      )
      path
      isProcessing
      isPinned
      name
      totalSpend(
        startDate: $startDate
        endDate: $endDate
        states: $states
      )
      kgCo2eOffset
      kgCo2e(
        startDate: $startDate
        endDate: $endDate
        states: $states
      )
      kgCo2ePerSpend(
        startDate: $startDate
        endDate: $endDate
        states: $states
      )
      userPermissions {
        read
        create
        edit
        delete
      }
      children {
        id
        identifier
      }
      measurementsTotalCount: measurements(
        states: $states
        startDate: $startDate
        endDate: $endDate
      ) {
        totalCount
      }
    }
    goal {
      id
      title
      identifier
      percentReduction
    }
    emissionRatesPerTime {
      kgCo2ePerTime
      datetime
    }
    userPermissions {
      read
      create
      edit
      delete
    }
  }
`;

const PUBLIC_BRANCH_FRAGMENT = gql`
  fragment PublicBranchFragment on PublicBranchObject {
    id
    identifier
    branchType
    index
    name
    missionStatement
    visionStatement
    tree {
      entity {
        identifier
        badge
        score
        personalMessage
        sustainabilityStatement
        logo {
          name
          size
          url
        }
      }
    }
    headerImage {
      id
      identifier
      isImage
      isPublic
      file {
        name
        size
        url
      }
    }
    images {
      id
      sortOrder
      identifier
      isPublic
      isImage
      file {
        name
        size
        url
      }
    }
    documents {
      id
      identifier
      isImage
      file {
        name
        size
        url
      }
    }
    path
    kgCo2e
    scope1
    scope2
    scope3
    parent {
      identifier
      name
      path
    }
    goals {
      identifier
      title
      description
      frequency
      percentReduction
      emissionRatesPerTime {
        actualKgCo2ePerTime
        projectedKgCo2ePerTime
        targetKgCo2ePerTime
        datetime
      }
      actions {
        id
        identifier
        title
        description
        category
        startDate
        endDate
        state
        goal {
          title
        }
      }
      baselineEndDate
      baselineStartDate
      baselineEmissionRate
      targetEmissionRate
      targetEndDate
    }
    kgCo2ePerSpend
    kgCo2eOffset
    highestEmittingCategories(
      startDate: $startDate
      endDate: $endDate
      scope: [SCOPE_1, SCOPE_2, SCOPE_3]
    ) {
      ghgCategory
      kgCo2e
    }
    highestEmittingScope1Categories: highestEmittingCategories(
      startDate: $startDate
      endDate: $endDate
      scope: [SCOPE_1]
    ) {
      ghgCategory
      kgCo2e
    }
    highestEmittingScope2Categories: highestEmittingCategories(
      startDate: $startDate
      endDate: $endDate
      scope: [SCOPE_2]
    ) {
      ghgCategory
      kgCo2e
    }
    highestEmittingScope3Categories: highestEmittingCategories(
      startDate: $startDate
      endDate: $endDate
      scope: [SCOPE_3]
    ) {
      ghgCategory
      kgCo2e
    }

    children {
      identifier
      branchType
      name
      path
      kgCo2e
      parent {
        identifier
        name
      }
      scope1
      scope2
      scope3
    }
  }
`;
const EDITABLE_PUBLIC_PAGE_BRANCH_FRAGMENT = gql`
  fragment EditablePublicPageBranchFragment on BranchObject {
    id
    identifier
    branchType
    name
    missionStatement
    tree {
      entity {
        id
        identifier
        badge
        score
        personalMessage
        sustainabilityStatement
        logo {
          name
          size
          url
        }
      }
    }
    visionStatement
    headerImage {
      id
      identifier
      file {
        name
        size
        url
      }
    }
    images {
      id
      identifier
      sortOrder
      isPublic
      isImage
      file {
        name
        size
        url
      }
    }
    documents {
      id
      identifier
      isImage
      file {
        name
        size
        url
      }
    }
    path
    kgCo2e
    scope1
    scope2
    scope3
    parent {
      identifier
      name
      path
    }
    goals {
      identifier
      title
      description
      frequency
      percentReduction
      emissionRatesPerTime {
        actualKgCo2ePerTime
        projectedKgCo2ePerTime
        targetKgCo2ePerTime
        datetime
      }
      actions {
        id
        identifier
        goal {
          title
        }
        title
        description
        category
        state
        startDate
        endDate
        state
      }
      baselineEndDate
      baselineStartDate
      baselineEmissionRate
      targetEmissionRate
      targetEndDate
    }
    kgCo2ePerSpend
    kgCo2eOffset
    highestEmittingCategories(scope: [SCOPE_1, SCOPE_2, SCOPE_3]) {
      ghgCategory
      kgCo2e
    }
    highestEmittingScope1Categories: highestEmittingCategories(
      scope: [SCOPE_1]
    ) {
      ghgCategory
      kgCo2e
    }
    highestEmittingScope2Categories: highestEmittingCategories(
      scope: [SCOPE_2]
    ) {
      ghgCategory
      kgCo2e
    }
    highestEmittingScope3Categories: highestEmittingCategories(
      scope: [SCOPE_3]
    ) {
      ghgCategory
      kgCo2e
    }

    children {
      identifier
      branchType
      name
      path
      kgCo2e
      parent {
        identifier
        name
      }
      scope1
      scope2
      scope3
    }
  }
`;

export const TEAM_MEMBERS_FRAGMENT = gql`
  fragment TeamMembersFragment on TeamMemberObject {
    id
    identifier
    name
    title
    bio
    sortOrder
    image {
      name
      size
      url
    }
  }
`;

const PUBLIC_GOAL_FRAGMENT = gql`
  fragment PublicGoalFragment on PublicGoalObject {
    identifier
    title
    description
    frequency
    percentReduction
    emissionRatesPerTime {
      actualKgCo2ePerTime
      projectedKgCo2ePerTime
      targetKgCo2ePerTime
      datetime
    }
    actions {
      id
      identifier
      title
      description
      category
      startDate
      endDate
      state
      goal {
        identifier
        title
      }
    }
    baselineEndDate
    baselineStartDate
    baselineEmissionRate
    targetEmissionRate
    targetEndDate
  }
`;

const PUBLIC_AGGREGATE_MEASUREMENTS_FRAGMENT = gql`
  fragment PublicAggregateMeasurementsFragment on BranchObject {
    publicAggregateMeasurements: aggregateMeasurements(
      startDate: $startDate
      endDate: $endDate
      states: [PUBLIC]
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          identifier
          name
          branch {
            identifier
            name
            path
          }
        }
      }
    }
  }
`;

const PUBLIC_AGGREGATE_MEASUREMENTS_AMOUNT_FRAGMENT = gql`
  fragment PublicAggregateMeasurementsAmountFragment on BranchObject {
    publicAggregateMeasurementsAmount: aggregateMeasurements(
      startDate: $startDate
      endDate: $endDate
      states: [PUBLIC]
    ) {
      totalCount
    }
  }
`;

const TO_REVIEW_AGGREGATE_MEASUREMENTS_FRAGMENT = gql`
  fragment ToReviewAggregateMeasurementsFragment on BranchObject {
    toReviewAggregateMeasurements: aggregateMeasurements(
      startDate: $startDate
      endDate: $endDate
      states: [TO_REVIEW]
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          identifier
          name
          branch {
            path
          }
        }
      }
    }
  }
`;

const RECORDED_AGGREGATE_MEASUREMENTS_FRAGMENT = gql`
  fragment RecordedAggregateMeasurementsFragment on BranchObject {
    recordedAggregateMeasurements: aggregateMeasurements(
      startDate: $startDate
      endDate: $endDate
      states: [RECORDED]
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          identifier
        }
      }
    }
  }
`;

const EXCLUDED_AGGREGATE_MEASUREMENTS_FRAGMENT = gql`
  fragment ExcludedAggregateMeasurementsFragment on BranchObject {
    excludedAggregateMeasurements: aggregateMeasurements(
      startDate: $startDate
      endDate: $endDate
      states: [EXCLUDED]
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          identifier
        }
      }
    }
  }
`;

const AGGREGATE_MEASUREMENTS_AMOUNT_FRAGMENT = gql`
  fragment AggregateMeasurementsAmountFragment on BranchObject {
    aggregateMeasurementsAmount: aggregateMeasurements(
      startDate: $startDate
      endDate: $endDate
      states: $states
    ) {
      totalCount
    }
  }
`;

const AGGREGATE_MEASUREMENTS_FRAGMENT = gql`
  fragment AggregateMeasurementsFragment on BranchObject {
    aggregateMeasurements(
      startDate: $startDate
      endDate: $endDate
      states: $states
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          identifier
          name
          description
          value
          kgCo2e
          state
          branch {
            id
            identifier
            path
            userPermissions {
              # this property is used in BranchMeasurementsList
              delete
            }
          }
        }
      }
    }
  }
`;

export const BRANCH_STATE_FRAGMENT = gql`
  fragment BranchStateFragment on BranchObject {
    id
    identifier
    isProcessing
    name
    isBranchOrChildrenProcessing
    isNeedingAttention(
      startDate: $startDate
      endDate: $endDate
      states: $states
    )
    kgCo2e(startDate: $startDate, endDate: $endDate, states: $states)
    scope1(startDate: $startDate, endDate: $endDate, states: $states)
    scope2(startDate: $startDate, endDate: $endDate, states: $states)
    scope3(startDate: $startDate, endDate: $endDate, states: $states)
    parent {
      isNeedingAttention(
        startDate: $startDate
        endDate: $endDate
        states: $states
      )
      isProcessing
      isBranchOrChildrenProcessing
      kgCo2e(
        startDate: $startDate
        endDate: $endDate
        states: $states
      )
      scope1(
        startDate: $startDate
        endDate: $endDate
        states: $states
      )
      scope2(
        startDate: $startDate
        endDate: $endDate
        states: $states
      )
      scope3(
        startDate: $startDate
        endDate: $endDate
        states: $states
      )
    }
  }
`;

export const MEASUREMENT_FRAGMENT = gql`
  fragment MeasurementFragment on MeasurementObject {
    id
    identifier
    index
    createdAt
    description
    modifiedAt
    dateOfMeasurement
    avoidedAfterEndDate
    name
    note
    scope
    value
    valueUnit
    ghgCategory
    state
    kgCo2e
    multiplier
    ownershipFraction
    isRecurring
    intervalValue
    intervalUnit
    scope1
    scope2
    scope3
    ghgCategory
    classificationPath
    previousMeasurements {
      id
      identifier
      name
      kgCo2e
      startDate
      endDate
      branch {
        id
        identifier
        path
      }
    }
    subsequentMeasurements {
      id
      identifier
      name
      kgCo2e
      startDate
      endDate
      branch {
        id
        identifier
        path
      }
    }
    branch {
      id
      identifier
      name
      path
      branchType
      isNeedingAttention(
        startDate: $startDate
        endDate: $endDate
        states: $states
      )
      userPermissions {
        read
        create
        edit
        delete
      }
    }
    emissionFactor {
      identifier
      name
      rate
      unspscPath
      scope
      unitInfo {
        __typename
        ... on UnitInfoEnumObject {
          unit
          type
        }
      }
      reference {
        source
        organization
        country
        url
        year
      }
    }
    supportingDocuments {
      ...SupportingDocumentFragment
    }
    calculatorType
    startDate
    endDate
    location {
      identifier
      name
    }
    vehicle {
      identifier
      name
    }
    equipment {
      identifier
      name
    }
    strategy {
      identifier
      title
    }
  }
`;

const EMISSION_FACTOR_FRAGMENT = gql`
  fragment EmissionFactorFragment on EmissionFactorObject {
    identifier
    name
    description
    rate
    unspscPath
    scope
    unitInfo {
      __typename
      ... on UnitInfoEnumObject {
        unit
        type
      }
    }
    reference {
      source
      organization
      country
      url
      year
    }
    isPublic
  }
`;

const SUPPORTING_DOCUMENT_FRAGMENT = gql`
  fragment SupportingDocumentFragment on SupportingDocumentObject {
    id
    identifier
    isPublic
    isImage
    sortOrder
    file {
      name
      size
      url
    }
    createdAt
  }
`;

const PUBLIC_MEASUREMENT_FRAGMENT = gql`
  fragment PublicMeasurementFragment on PublicMeasurementObject {
    id
    identifier
    index
    name
    kgCo2e
    scope
    branch {
      id
      identifier
      path
    }
    supportingDocuments {
      ...SupportingDocumentFragment
    }
  }
`;

const GROUP_FRAGMENT = gql`
  fragment GroupFragment on GroupObject {
    id
    identifier
    name
    createdAt
    isDefault
    permissions(first: $first, after: $after, offset: $offset) {
      totalCount
      edges {
        node {
          id
          identifier
          read
          create
          edit
          delete
          branch {
            id
            identifier
            name
          }
        }
      }
    }
    accounts(first: $first, after: $after, offset: $offset) {
      totalCount
      edges {
        node {
          id
          identifier
          isOwner
          user {
            id
            identifier
            emailAddress
            profile {
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;

const ALL_USERS_FRAGMENT = gql`
  fragment SettingsAllUsersFragment on UserObject {
    id
    identifier
    profile {
      firstName
      lastName
    }
    emailAddress
    accounts {
      edges {
        node {
          id
          identifier
          entity {
            id
            identifier
            name
            tree {
              head {
                name
              }
            }
          }
        }
      }
    }
  }
`;

const IMPORTED_BRANCH_FRAGMENT = gql`
  fragment ImportedBranchFragment on BranchObject {
    ...BranchFragment
    measurements(
      startDate: $startDate
      endDate: $endDate
      states: $states
    ) {
      totalCount
      edges {
        node {
          id
          identifier
          value
        }
      }
    }
    toReviewKgCo2e: kgCo2e(states: TO_REVIEW)
    fileType
    plaidItem {
      ...PlaidItemFragment
    }
  }
`;

const PLAID_ITEM_FRAGMENT = gql`
  fragment PlaidItemFragment on PlaidItemObject {
    id
    itemId
    institutionId
    institutionName
    plaidAccounts {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        node {
          identifier
          name
          accountType
          accountSubtype
          officialName
          updatedAt
        }
      }
    }
  }
`;

export const SETTINGS_USER_FRAGMENT = gql`
  fragment SettingsUserFragment on UserObject {
    identifier
    createdAt
    modifiedAt
    emailAddress
    emailVerified
    isSuperuser
    isStaff
    isPasswordSet
    profile {
      firstName
      lastName
      createdAt
      phone
    }
    activeAccount {
      isOwner
      entity {
        id
        identifier
        billing {
          identifier
          availableCredit
          currentMonthlyUsage
          nextInvoiceDate
          activePlan {
            name
            description
            price
            monthlyUsageLimit
            expensesIncluded
            tier
            frequency
            credit
          }
          paymentMethods {
            lastFour
            expMonth
            expYear
            brand
          }
        }
        tree {
          identifier
          head {
            name
            identifier
          }
        }
      }
      user {
        identifier
        emailAddress
        profile {
          firstName
          lastName
        }
      }
      id
      identifier
    }
  }
`;

const CART_FRAGMENT = gql`
  fragment CartFragment on CartObject {
    identifier
    subtotal
    items(first: 100) {
      edges {
        node {
          identifier
          amount
          emitiqListing {
            identifier
            price
            emitiq {
              project {
                identifier
                name
                amountListed
                attributes {
                  identifier
                  name
                }
              }
            }
          }
        }
      }
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

const LOCATION_FRAGMENT = gql`
  fragment LocationFragment on LocationObject {
    id
    identifier
    name
    addressLine1
    addressLine2
    city
    state
    postalCode
    country
    area
    areaUnit
    longitude
    latitude
    hasElectricity
    hasNaturalGas
    hasHeatOrSteam
    hasPurchasedCooling
    category
    subcategory
    icon
    electricityMeasurements(
      reportingPeriodIdentifier: $reportingPeriodIdentifier
    ) {
      kgCo2e
    }
    naturalGasMeasurements(
      reportingPeriodIdentifier: $reportingPeriodIdentifier
    ) {
      kgCo2e
    }
    heatOrSteamMeasurements(
      reportingPeriodIdentifier: $reportingPeriodIdentifier
    ) {
      kgCo2e
    }
    purchasedCoolingMeasurements(
      reportingPeriodIdentifier: $reportingPeriodIdentifier
    ) {
      kgCo2e
    }
    dataCalculators(
      reportingPeriodIdentifier: $reportingPeriodIdentifier
    ) {
      calculatorType
      measurements {
        dateOfMeasurement
        identifier
        intervalValue
        intervalUnit
      }
      missingMeasurementDateRanges {
        startDate
        endDate
      }
    }
    vehicles {
      identifier
      icon
      name
      vehicleType
      numberOfUnits
    }
    equipment {
      identifier
      icon
      name
      equipmentType
      numberOfUnits
    }
    entity {
      identifier
    }
  }
`;

const VEHICLE_FRAGMENT = gql`
  fragment VehicleFragment on VehicleObject {
    id
    identifier
    entity {
      identifier
    }
    location {
      id
      identifier
      name
      category
      subcategory
      addressLine1
      city
      state
      country
    }
    name
    numberOfUnits
    make
    model
    year
    fuelType
    vehicleType
    icon
    fuelMeasurements(
      reportingPeriodIdentifier: $reportingPeriodIdentifier
    ) {
      kgCo2e
    }
    distanceMeasurements(
      reportingPeriodIdentifier: $reportingPeriodIdentifier
    ) {
      kgCo2e
    }
    dataCalculators(
      reportingPeriodIdentifier: $reportingPeriodIdentifier
    ) {
      calculatorType
      measurements {
        dateOfMeasurement
        identifier
        name
        intervalValue
        intervalUnit
        startDate
        endDate
      }
      missingMeasurementDateRanges {
        startDate
        endDate
      }
    }
  }
`;

const EQUIPMENT_FRAGMENT = gql`
  fragment EquipmentFragment on EquipmentObject {
    id
    identifier
    entity {
      identifier
    }
    location {
      id
      identifier
      name
      category
      subcategory
      addressLine1
      city
      state
      country
    }
    name
    equipmentType
    equipmentSubtype
    fuelType
    numberOfUnits
    icon
    hasFuel
    hasRefrigerant
    hasIndustrialGas
    fuelMeasurements(
      reportingPeriodIdentifier: $reportingPeriodIdentifier
    ) {
      kgCo2e
    }
    refrigerantMeasurements(
      reportingPeriodIdentifier: $reportingPeriodIdentifier
    ) {
      kgCo2e
    }
    industrialGasMeasurements(
      reportingPeriodIdentifier: $reportingPeriodIdentifier
    ) {
      kgCo2e
    }
    dataCalculators(
      reportingPeriodIdentifier: $reportingPeriodIdentifier
    ) {
      calculatorType
      measurements {
        dateOfMeasurement
        identifier
        name
        intervalValue
        intervalUnit
        startDate
        endDate
      }
      missingMeasurementDateRanges {
        startDate
        endDate
      }
    }
  }
`;

const RECURRING_MEASUREMENTS_MISSING_DATA_FRAGMENT = gql`
  fragment RecurringMeasurementsMissingSubsequentDataFragment on MeasurementObject {
    id
    identifier
    name
    dateOfMeasurement
    intervalValue
    intervalUnit
    kgCo2e
    calculatorType
    ghgCategory
    scope
    branch {
      identifier
    }
  }
`;

const TARGET_FRAGMENT = gql`
  fragment TargetFragment on TargetObject {
    id
    identifier
    targetType
    isSbtCompatible
    scope1Applicable
    scope2Applicable
    scope3Applicable
    baselineStart
    baselineEnd
    targetEnd
    targetReductionPercentage
    maintenanceFrequency
    maintainer {
      identifier
    }
    reductionEstimate
    targetCompliantPercentage
    absoluteReduction
    intensityReduction
    targetReductionPercentage
    entity {
      id
      identifier
      publicBranchIdentifier
    }
    earliestAssociatedMeasurementDate
  }
`;

const ACTION_VIEW_TARGET_FRAGMENT = gql`
  fragment ActionViewTargetFragment on TargetObject {
    id
    identifier
    strategies {
      identifier
      title
      actions {
        id
        identifier
        title
        description
        implementationCost
        implementationTimeHours
        dueDate
        status
        checkinFrequency
        assignee {
          profile {
            firstName
            lastName
          }
        }
        template {
          identifier
        }
      }
    }
  }
`;

const STRATEGY_FRAGMENT = gql`
  fragment StrategyFragment on StrategyObject {
    id
    identifier
    title
    description
    ghgCategory
    applicableDataCalculatorType
    applicableAssetTypes
    applicableAssetSubtypes
    checkinFrequency
    percentContributionToGoal
    actualReductionPercentage
    reductionPercentageEstimate
    implementationCost
    implementationTimeHours
    notes
    template {
      identifier
    }
    actions {
      id
      identifier
      title
      description
      implementationCost
      implementationTimeHours
      dueDate
      status
      checkinFrequency
      assignee {
        profile {
          firstName
          lastName
        }
      }
      template {
        identifier
      }
    }
    target {
      id
    }
    measurements {
      identifier
    }
  }
`;

const ACTION_FRAGMENT = gql`
  fragment ActionFragment on ActionObject {
    id
    identifier
    assignee {
      identifier
      profile {
        firstName
        lastName
      }
    }
    strategy {
      id
      identifier
      title
    }
    template {
      id
      identifier
    }
    title
    description
    implementationCost
    implementationTimeHours
    dueDate
    status
    checkinFrequency
    notes
    supportingDocuments {
      identifier
      file {
        name
        size
      }
    }
  }
`;

const STRATEGY_TEMPLATE_FRAGMENT = gql`
  fragment StrategyTemplateFragment on StrategyTemplateObject {
    id
    identifier
    author {
      identifier
      emailAddress
      profile {
        firstName
        lastName
      }
    }
    title
    description
    reductionPercentageEstimate
    implementationCostEstimate
    implementationTimeEstimate
    ghgCategory
    applicableDataCalculatorType
    applicableAssetTypes
    applicableAssetSubtypes
    applicableSectors
    reductionPotentialScore
    complexityScore
    timeScore
    costScore
    incentiveScore
    recommendedCheckinFrequency
    notes
    verified
    actionTemplates {
      ...ActionTemplateFragment
    }
  }
`;

const ACTION_TEMPLATE_FRAGMENT = gql`
  fragment ActionTemplateFragment on ActionTemplateObject {
    id
    identifier
    title
    description
    implementationCostEstimate
    implementationTimeEstimate
    recommendedCheckinFrequency
    notes
    strategyTemplate {
      title
      identifier
      verified
      author {
        identifier
      }
    }
    supportingDocuments {
      identifier
      file {
        name
        size
      }
    }
  }
`;

const INCENTIVE_FRAGMENT = gql`
  fragment IncentiveFragment on IncentiveObject {
    id
    identifier
    author {
      identifier
    }
    strategies {
      identifier
      title
    }
    strategyTemplates {
      identifier
      title
    }
    title
    description
    deadline
    url
    cashValue
    notes
    isActive
    applicableRegion
    supportingDocuments {
      identifier
    }
  }
`;

export const FRAGMENTS = [
  ERRORS_FRAGMENT,
  IMPORTED_BRANCH_FRAGMENT,
  USER_FRAGMENT,
  SETTINGS_USER_FRAGMENT,
  ALL_USERS_FRAGMENT,
  PROJECT_FRAGMENT,
  BRANCH_FRAGMENT,
  PUBLIC_BRANCH_FRAGMENT,
  BRANCH_STATE_FRAGMENT,
  AGGREGATE_MEASUREMENTS_FRAGMENT,
  AGGREGATE_MEASUREMENTS_AMOUNT_FRAGMENT,
  PUBLIC_AGGREGATE_MEASUREMENTS_FRAGMENT,
  PUBLIC_AGGREGATE_MEASUREMENTS_AMOUNT_FRAGMENT,
  TO_REVIEW_AGGREGATE_MEASUREMENTS_FRAGMENT,
  RECORDED_AGGREGATE_MEASUREMENTS_FRAGMENT,
  EXCLUDED_AGGREGATE_MEASUREMENTS_FRAGMENT,
  GOAL_FRAGMENT,
  GOAL_ACTION_FRAGMENT,
  MEASUREMENT_FRAGMENT,
  PUBLIC_MEASUREMENT_FRAGMENT,
  GROUP_FRAGMENT,
  PLAID_ITEM_FRAGMENT,
  REPORT_FRAGMENT,
  REPORT_GROUP_FRAGMENT,
  REPORT_ANSWER_FRAGMENT,
  REPORT_QUESTION_FRAGMENT,
  CONTEXTUAL_REPORT_ANSWER_FRAGMENT,
  PUBLIC_GOAL_FRAGMENT,
  CART_FRAGMENT,
  SURVEY_SUBMISSION_FRAGMENT,
  TEAM_MEMBERS_FRAGMENT,
  EDITABLE_PUBLIC_PAGE_BRANCH_FRAGMENT,
  SURVEY_SUBMISSION_ACCOUNT_FRAGMENT,
  PUBLIC_SURVEY_FRAGMENT,
  PUBLIC_ENTITY_FRAGMENT,
  EMISSION_FACTOR_FRAGMENT,
  ENTITY_FRAGMENT,
  BILLING_FRAGMENT,
  BRANCH_SELECTOR_BRANCH_FRAGMENT,
  REPORTING_PERIOD_SELECTOR_PERIOD_FRAGMENT,
  LOCATION_FRAGMENT,
  VEHICLE_FRAGMENT,
  EQUIPMENT_FRAGMENT,
  SUPPORTING_DOCUMENT_FRAGMENT,
  TARGET_FRAGMENT,
  ACTION_FRAGMENT,
  ACTION_TEMPLATE_FRAGMENT,
  STRATEGY_TEMPLATE_FRAGMENT,
  INCENTIVE_FRAGMENT,
  ACTION_VIEW_TARGET_FRAGMENT,
  STRATEGY_FRAGMENT,
  STRATEGY_TEMPLATE_FRAGMENT,
  ACTION_TEMPLATE_FRAGMENT,
  BLOGPOST_FRAGMENT,
  RECURRING_MEASUREMENTS_MISSING_DATA_FRAGMENT
  // External Fragments
];
